let buttons = document.querySelectorAll(".about_link");

buttons.forEach(button => {
    button.addEventListener("mouseover", e => {
        let x = ((e.offsetX / button.offsetWidth) * 100).toFixed(2);
        let y = ((e.offsetY / button.offsetHeight) * 100).toFixed(2);
        button.style.setProperty('--_x', x + '%');
        button.style.setProperty('--_y', y + '%');
        
        // Примусово перезапустити рендеринг
        button.getBoundingClientRect();

        button.classList.add("hovered");
    });

    button.addEventListener("mouseout", e => {
        let x = ((e.offsetX / button.offsetWidth) * 100).toFixed(2);
        let y = ((e.offsetY / button.offsetHeight) * 100).toFixed(2);
        button.style.setProperty('--_x', x + '%');
        button.style.setProperty('--_y', y + '%');
        
        // Примусово перезапустити рендеринг
        button.getBoundingClientRect();

        button.classList.remove("hovered");
    });
});
