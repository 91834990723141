document.addEventListener("DOMContentLoaded", function () {
  const gs = document.querySelector("#gallery");
  const vs = document.querySelector("#videoHome");
  function isTouchDevice() {
    return navigator.maxTouchPoints > 0;
  }
  function isElementInViewport(element, callback) {
    if (!element) return;

    const observerOptions = {
      root: null, // Використовуємо вікно браузера як корінь
      rootMargin: "0px",
      threshold: 0.1, // Вказуємо поріг видимості 10%
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(true);
        } else {
          callback(false);
        }
        observer.disconnect(); // Зупиняємо спостереження після першого виклику
      });
    }, observerOptions);

    observer.observe(element);
  }

  window.addEventListener("resize", function () {
    [
      { element: gs, className: "mobile-gallery" },
      { element: vs, className: "mobile-video" },
    ].forEach(({ element, className }) => {
      isElementInViewport(element, (isVisible) => {
        if (
          isTouchDevice() &&
          window.innerWidth < window.innerHeight &&
          isVisible
        ) {
          element.classList.remove(className);
        } else if (isVisible && isTouchDevice()) {
          element.classList.add(className);
        }
      });
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const video = document.querySelector("#videoHome video");
  document
	.querySelectorAll("#videoHome .sound, #videoHome .no-sound")
    .forEach((button) => {
      button.addEventListener("click", function () {
        if (video.muted) {
          video.muted = false;
          document.querySelector("#videoHome").classList.add("with-sound");
        } else {
					video.muted = true;
          document.querySelector("#videoHome").classList.remove("with-sound");
					
        }
      });
    });
});
