import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/all"

gsap.registerPlugin(ScrollTrigger)

export function animateBgPattern() {
	const bgPattern = document.querySelector(".bg-pattern")
	if(!bgPattern) return
    const bgPatternPaths = bgPattern.querySelectorAll('.bg-pattern path')
		if(!bgPatternPaths) return
    const pathsTimeline = gsap.timeline()

    bgPatternPaths.forEach(el => {
        gsap.set(el, {
            strokeDasharray: `${el.getTotalLength()}`,
            strokeDashoffset: `${el.getTotalLength()}`
        })

       const pathTimeline = gsap.timeline().to(el, {
           strokeDashoffset: 0,
       })

        pathsTimeline.add(pathTimeline)
    })

    ScrollTrigger.create({
        trigger: bgPattern,
        start: "bottom 80%",
        end: "150% 80%",
        markers: true,
        scrub: true,
        animation: pathsTimeline
    })
}

animateBgPattern()