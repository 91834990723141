let titleList = document.querySelectorAll(".docs-headings-list__item .docs-headings-list__title");
let blocks = document.querySelectorAll(".docs-content-list__item");

let prevIndex = 1;
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      const rect = entry.target.getBoundingClientRect();
      const inMiddle = rect.top >= window.innerHeight / 2 - rect.height / 2 && rect.bottom <= window.innerHeight / 2 + rect.height / 2;

      if (entry.isIntersecting || inMiddle) {
        const index = parseInt(entry.target.id);
        prevIndex = index;
        addToActive(index);
      } else if (rect.top < 0 && !entry.isIntersecting) {
        prevIndex = parseInt(entry.target.id) + 1;
        addToActive(prevIndex - 1);
      }
    });
  },
  { threshold: [0,1], rootMargin: "-50% 0px -50% 0px" }
);

if (blocks.length && titleList.length) {
  window.onload = () => {
    addToActive(prevIndex);
  };
  blocks.forEach((block) => {
    observer.observe(block);
  });
}

let addToActive = (toIndex) => {
  if (toIndex < 0 || toIndex >= titleList.length) return;
  titleList.forEach((title) => {
    try {
      title.classList.remove("is-active");
    } catch (e) {
      console.log(e);
    }
  });
  titleList[toIndex].classList.add("is-active");
};
