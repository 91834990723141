window.addEventListener("message", function (e) {
  const url = params.template_directory_url;

  let data = e.data;
  if (data.event === "g_widget:load") {
    let iframe = document.getElementById("layout-iframe");
    let dataCss = {
      action: "add_css",
      url: `${url}/styles/mainplan.css`,
    };
    let colors = {
      action: "settings",
      default_floor_style: {
        color: "#fff",
        fillOpacity: 0.3,
        editing: false,
        opacity: 1,
      },
      hover_style: { color: "#fff", fillOpacity: 0.6, editing: false },
      default_flat_style: {
        color: "#fff",
        fillOpacity: 0.3,
        editing: false,
        opacity: 1,
      },
      booked_flat_style: { color: "#fff", fillOpacity: 0, editing: false },
      sold_flat_style: { color: "#fff", fillOpacity: 0, editing: false },
    };
    let dataJs = {
      action: "add_js",
      url: `${url}/assets/js/libs/custom.js`,
    };
    iframe.contentWindow.postMessage(dataJs, "https://crm.g-plus.app");
    iframe.contentWindow.postMessage(dataCss, "https://crm.g-plus.app");
    iframe.contentWindow.postMessage(colors, "https://crm.g-plus.app");
  }
});
