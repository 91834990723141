document.addEventListener("DOMContentLoaded", function () {
  if (!sessionStorage.getItem("visited")) {
		document.querySelector(".preloader")?.classList.add('loaded')
		setTimeout(() => {
			sessionStorage.setItem("visited", "true");
			document.querySelector(".preloader")?.remove();
		}, parseInt(getComputedStyle(document.querySelector('.preloader'))?.getPropertyValue('--_anim-close')))
  } else {
		document.querySelector(".preloader")?.remove();
	}
	if(window.location.pathname !== '/'){
		sessionStorage.setItem("visited", "true");
	}
});
