let next = document.querySelector('.next-button');
let prev = document.querySelector('.prev-button');

if (next && prev) { // Перевірка на наявність елементів
    next.addEventListener('click', function(){
        let items = document.querySelectorAll('.carousel-list__item');
        if (items.length > 0) { // Перевірка наявності елементів
            document.querySelector('.carousel-list').appendChild(items[0]);
        }
    });

    prev.addEventListener('click', function(){
        let items = document.querySelectorAll('.carousel-list__item');
        if (items.length > 0) { // Перевірка наявності елементів
            document.querySelector('.carousel-list').prepend(items[items.length - 1]);
        }
    });
}