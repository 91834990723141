import Swiper from "swiper/bundle";
import "swiper/css/bundle";


const swiperGallery = new Swiper('.gallery-swiper', {


    // scrollbar: '.swiper-scrollbar',
    // nextButton: '.swiper-button-next',
    // prevButton: '.swiper-button-prev',
    parallax: true,
    speed: 1000,
    loop: true,
    autoplay: 1000,
    autoplayDisableOnInteraction: false,
    pagination: {
        el: ".gallery-swiper .swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
				dynamicBullets: true,
      	dynamicMainBullets: 6,
    },

});

const swiperGalleryMob = new Swiper('.gallery-swiper--mob', {

    spaceBetween: 15,
    slidesPerView: 1,
    parallax: true,
    speed: 1000,
    loop: true,
    autoplay: 1000,
    autoplayDisableOnInteraction: false,
    pagination: {
        el: ".gallery-swiper--mob .swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
				dynamicBullets: true,
      	dynamicMainBullets: 4,
    },
    navigation: {
        prevEl: ".prev",
        nextEl: ".next",
    },

});

const swiperCharacteristics = new Swiper(".characteristics-swiper", {
	direction: "vertical",
	mousewheel: {
			forceToAxis: true,
			releaseOnEdges: true,
			thresholdTime: 2400,
	},
	spaceBetween: 15,
	breakpoints: {
			992: {
					pagination: {
							type: "progressbar",
							el: ".characteristics-wrapper .swiper-pagination",
							clickable: true,
					},
			}
	},
	slidesPerView: "auto",
	on: {
    slideChangeTransitionStart: function (e) {
			const slides = e.slides;
      const previousIndex = e.previousIndex;
      const activeIndex = e.activeIndex;
      
      slides[previousIndex].classList.add('fade-out');
			setTimeout(() => {
				slides[activeIndex].classList.add('fade-in');
			}, 990);
			setTimeout(() => {
        slides[previousIndex].classList.remove('fade-out', 'swiper-slide-active');
      }, 1300); 
    },
    slideChangeTransitionEnd: function (e) {
			const slides = e.slides;
      const activeIndex = e.activeIndex;

      setTimeout(() => {
        slides[activeIndex].classList.remove('fade-in');
      }, 1000);
		}
	}
});



const swiperCharacteristicsContent = new Swiper(".characteristics-content-swiper", {
    direction: "vertical",
    slidesPerView: 1,
    mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true,
        thresholdTime: 2400,
    },
    breakpoints:{
        992:{
            pagination: {
                type: "progressbar",
                el: ".characteristics-wrapper .swiper-pagination",
                clickable: true,
            },
        }
    },

    pagination: {
        type: "progressbar",
        el: ".characteristics-wrapper .swiper-pagination--mob",
        clickable: true,
    },
		on: {
			slideChangeTransitionStart: function (e) {
				const slides = e.slides;
				const previousIndex = e.previousIndex;
				const activeIndex = e.activeIndex;
				
				slides[previousIndex].classList.add('fade-out');
				setTimeout(() => {
					slides[activeIndex].classList.add('fade-in');
				}, 690);
				setTimeout(() => {
					slides[previousIndex].classList.remove('fade-out', 'swiper-slide-active');
				}, 1300); 
			},
			slideChangeTransitionEnd: function (e) {
				const slides = e.slides;
				const activeIndex = e.activeIndex;
	
				setTimeout(() => {
					slides[activeIndex].classList.remove('fade-in');
				}, 1000);
			}
		}
});



if(swiperCharacteristics && swiperCharacteristicsContent){
	swiperCharacteristicsContent.on('slideChangeTransitionStart', function(e) {
		swiperCharacteristics.slideTo(e.activeIndex);
	});
	swiperCharacteristics.on('slideChangeTransitionStart', function(e) {
		swiperCharacteristicsContent.slideTo(e.activeIndex);
	});
}

function enableKeyboardControl(swiper) {
  var isThrottled = false;

  document.addEventListener('keydown', function(event) {
    if (isThrottled) return;

    if (event.key === 'ArrowUp' && !swiper.isBeginning) {
      swiper.slidePrev();
      isThrottled = true;
      setTimeout(() => { isThrottled = false; }, swiper.params.mousewheel.thresholdTime);
    } else if (event.key === 'ArrowDown' && !swiper.isEnd) {
      swiper.slideNext();
      isThrottled = true;
      setTimeout(() => { isThrottled = false; }, swiper.params.mousewheel.thresholdTime);
    }
  });
}

const  isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

window.addEventListener('load', function() {
  if (!isTouchDevice()) {
    enableKeyboardControl(swiperCharacteristics);
  }
});





const swiperAbout = new Swiper(".about-swiper", {
    slidesPerView: 'auto',
    spaceBetween: 9,
    breakpoints: {
        992: {
            spaceBetween: 200,
        }

    },
    navigation: {
        prevEl: ".prev",
        nextEl: ".next",
    },
    grabCursor: true,
    autoHeight: false,
    speed: 1500,

});

const swiperAboutText = new Swiper(".about-text-swiper", {
    slidesPerView: '1',
    // direction:"vertical",
    allowTouchMove: false,
    grabCursor: true,
    autoHeight: false,
    speed: 1500,
		spaceBetween: 0,
    breakpoints: {
			992: {
					spaceBetween: 16,
          direction: "vertical"
        }
    },

});
const swiperAboutWrapper = new Swiper(".about-text-wrapper", {
    slidesPerView: '1',
    // direction: "vertical",
    grabCursor: true,
    autoHeight: false,
    speed: 1500,
    loop: true,
    allowTouchMove: false,

    breakpoints: {
        992: {

            direction: "vertical"
        }
    },

});

swiperAbout.controller.control = swiperAboutText
swiperAboutText.controller.control = swiperAbout && swiperAboutWrapper






// Функція для виведення координат кліку

const swiperEcosystem = new Swiper(".ecosystem-swiper", {
    slidesPerView: "auto",
    autoHeight: false,
    allowTouchMove: false,
		touchStartPreventDefault: false,
    speed: 1500,
    // loop: true,
    spaceBetween: 25,

    breakpoints: {
        992: {
            spaceBetween: 40,
        }
    },
});

const swiperTitles = new Swiper(".titles-swiper", {
    slidesPerView: "auto",
    direction: "vertical",
		touchStartPreventDefault: false,
});


swiperTitles.controller.control = swiperEcosystem;


document.addEventListener("DOMContentLoaded", function () {
    const cursorArrow = document.getElementById("cursorArrow");

    if (!cursorArrow) return; // Перевірка наявності елементу

    const screenWidthHalf = window.innerWidth / 2;

    document.addEventListener("mousemove", function (e) {
        cursorArrow.style.transform = `translate(${e.clientX - (cursorArrow.offsetWidth / 2)}px, ${e.clientY - (cursorArrow.offsetHeight / 2)}px)`;

        if (e.clientX < screenWidthHalf) {
            cursorArrow.classList.add('is-active');
        } else {
            cursorArrow.classList.remove('is-active');
        }
    });

    const gallerySwiper = document.querySelector('.gallery-swiper');


//перемикання слайдів по кліку направо чи наліво
    gallerySwiper.addEventListener('click', function (event) {
        const swiperWidth = gallerySwiper.offsetWidth;

        const clickX = event.clientX - gallerySwiper.getBoundingClientRect().left;

        const halfWidth = swiperWidth / 2;

        if (clickX > halfWidth) {
            swiperGallery.slideNext();
        } else {
            swiperGallery.slidePrev();
        }
    });
});


