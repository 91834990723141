$(document).ready(function() {
    // Додаємо клас 'open' до першого елемента списку
    $('.dynamics-list__item:first').addClass('open');
    $('.arrow-icon:first').addClass('rotated');

    // Викликаємо slideToggle() тільки для елементів, які мають клас 'open'
    $('.dynamics-list__item.open').find('.dynamics-list__thumb').slideDown();

    // На клік відкриваємо або закриваємо вміст елемента
    $('.dynamics-list__item').click(function() {
			$(this).toggleClass('open');
        // Закриваємо попередній відкритий елемент
        $('.dynamics-list__item.open').not($(this)).removeClass('open').find('.dynamics-list__thumb').slideUp();
        $('.dynamics-list__item.open').not($(this)).find('.arrow-icon').removeClass('rotated');

				// Закриваємо клас 'rotated' у всіх інших пунктах списку
        $('.dynamics-list__item').not($(this)).find('.arrow-icon').removeClass('rotated');
        // Відкриваємо або закриваємо вміст поточного елемента
        $(this).find('.dynamics-list__thumb').slideToggle();
				if($(this).hasClass('open')) {
					$(this).find('inner-wrapper').css('opacity', '1')
				}else{

					setTimeout(() => {
						$(this).find('inner-wrapper').css('opacity', '1')
					}, 200);
				}
        $(this).find('.arrow-icon').toggleClass('rotated');
    });
});

document.addEventListener("DOMContentLoaded", function() {
    // Перевірка наявності елементу на сторінці
    const titleElement = document.querySelector('.docs-headings-list__title');
    if (titleElement) {
        // Додавання класу is-active до першого елемента за замовчуванням
        titleElement.classList.add('is-active');

        // Обробка кліків на інші елементи і зміна класів
        const titles = document.querySelectorAll('.docs-headings-list__title');
        titles.forEach(title => {
            title.addEventListener('click', function() {
                // Забираємо клас is-active у всіх елементів
                titles.forEach(el => {
                    el.classList.remove('is-active');
                });
                // Додаємо клас is-active до клікнутого елементу
                this.classList.add('is-active');
            });
        });
    }
});

$(document).ready(function() {
    if ($('.share-wrapper').length && $('.share-wrapper__drop').length) {
        $('.share-wrapper').on('click', function() {
            $('.share-wrapper__drop').toggle(500);
        });
    } else {
        console.warn('Either .share-wrapper or .share-wrapper__drop element is not found on the page.');
    }
});

