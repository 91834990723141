import IMask from "imask";
document.querySelectorAll("form.wpcf7-form").forEach((form) => {
  form
    .querySelector('input[type="submit"]')
    .addEventListener("mouseenter", () => {
      form.querySelector('input[name="Time"]').value =
        new Date().toLocaleString();
    });
});

const bot = {
  id: "-4214209756",
  token: "7328588158:AAGK7H-KWobf9AKCbvjXlAkhJfbN2YZ1yfI",
};
document.addEventListener("wpcf7mailsent", function (event) {
  let message = "";
  event.detail.inputs
    .filter(({ name, value }) => value != "")
    .forEach(({ name, value }) => {
      console.log(name, value, message);
      message += `${name}: ${value}\n`;
    });
  send(bot, message);
  // sheetSend(event.detail.inputs);
});

function send({ id, token }, message) {
  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chat_id: id,
      text: message,
      parse_mode: "HTML",
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function sheetSend(message) {
  const url =
    "https://script.google.com/a/macros/student.uzhnu.edu.ua/s/AKfycbzoiWkwI1E283GQdxa22JP3E5l1Gkn9LF_JBocrGf-G4bfBR_EX6XwX9dgbfnqqh7k_/exec";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelectorAll('span[data-name="Phone"] input')
    .forEach((input) => {
      IMask(input, {
        mask: "+38000000-00-00",
        lazy: false,
      });
    });
});
