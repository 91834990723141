(function (a) {
  function init() {
    window.embed_layout = true;
    jQuery
      .ajax({
        url: "https://crm.g-plus.app/api/actions",
        method: "post",
        data: {
          action: "get-layout-widget",
          token: a,
          building_id: 25569,
          lang: "ua",
        },
      })
      .done(function (data) {
        jQuery(data.html).appendTo("body");

        // Приховуємо кнопку .g__building-button
        jQuery(".g__building-button").hide();

        // Додаємо обробник подій для кнопок .planning-building і .menu-item-645 chess-link
        jQuery(".planning-building, .menu-item-645, .chess-link").on(
          "click",
          function () {
            // Імітуємо клік по .g__building-button для відкриття планування
            jQuery(".g__building-button").trigger("click");
          }
        );
      });
  }

  if (typeof jQuery === "undefined") {
    var script = document.createElement("SCRIPT");
    script.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    script.type = "text/javascript";
    script.onload = function () {
      $ = window.jQuery;
      init();
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  } else {
    $ = window.jQuery;
    init();
  }
})("ace16435feaa41fba84ce583c65c7a88");
