import gsap from "gsap";

document.addEventListener("DOMContentLoaded", function () {
  const menu = document.getElementById("menu");
  const toggleButton = document.querySelector(".menu-button");
  const closeButton = document.querySelector(".close-btn");
  let closeButtonMobile = document.querySelector(".close-btn.d-lg-none");
  const body = document.body; // Отримання посилання на body

  let isOpen = false;

  toggleButton.addEventListener("click", function () {
    if (!isOpen) {
      body.style.overflow = "hidden";
      menu.classList.add("is-active");
			setTimeout(() => {
				console.log( menu.scrollTop)
			}, 2000);
			
    } else {
      body.style.overflow = "";
      menu.classList.remove("is-active");
    }
    isOpen = !isOpen;
  });

  [closeButton, closeButtonMobile].forEach(function (button) {
    button.addEventListener("click", function () {
      if (isOpen) {
        menu.classList.add("close");
        menu.classList.remove("is-active");
        setTimeout(() => {
          menu.classList.remove("close");
          body.style.overflow = "";
        }, 1500);
      }
      isOpen = !isOpen;
    });
  });
});
