$(document).ready(function() {
    $('.read-more-button').click(function() {
        $('.read-more-wrapper').slideToggle();
        const buttonText = $(this).text();
        if (buttonText.trim() === 'читати більше' || buttonText.trim() === 'read more') {
						if(buttonText.trim() === 'читати більше'){
							$(this).text('приховати');
						}else{
							$(this).text('hide');
						}
        } else { 
            if(buttonText.trim() === 'приховати'){
							$(this).text('читати більше');
						}else{
							$(this).text('read more');
						}
        }
    });
});
document.addEventListener("wpcf7mailsent", () => {
    const thankYouMessages = document.querySelectorAll('.ty-message');
    thankYouMessages.forEach(message => {
        message.style.opacity = '1';
        console.log(1);
    });
});






