import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";
import "intl-tel-input/build/css/intlTelInput.min.css";

import "./swiper";

import "./utils";
import "./menu";
import "./refs";
import "./accordion";
import "./modal";

import "./readMore";
import "./carousel";
import "./scrollHeaders";
import "./hideHeader";
import "./lockPreloader";
import "./animText";
import "./benefitsAnim";
import "./hoverButtonAnim";
import "./sinhronize";
import "./scrollToTop";
import "./triger-to-category";
import "./mobile-gallery";
import "./pixelLogic";
import "./sheets";
import "./libs/main";
import "./planning";

import "../../utils/animations/animateSvgPath";
import "../css/main.scss";
