const refs = {
  bodyEl: $("body"),

  appButtons: $(".app-button-js"),
  hideButton: $(".close-app-btn"),
  appBackdrop: $("#appointment"),
  appHideButton: $(".appointment-button"),
};

export default refs;
