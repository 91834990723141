import throttle from "lodash.throttle";
import {WOW} from "wowjs/dist/wow.min";
import refs from "./refs";

const {bodyEl} = refs;

const throttledHandleResize = throttle(handleResize, 200);

let currentBackdrop = null;

export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    $(window).on("keydown", handleKeyDown);
    currentBackdrop = backdrop;

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

const aboutTitles = document.querySelectorAll('.about-title');

// Перевірка чи існують елементи з класом 'about-title'
if (aboutTitles.length > 0) {
    // Додаємо клас 'active' до першого елемента 'about-title'
    aboutTitles[0].classList.add('active');

    aboutTitles.forEach((title, index) => {
        title.addEventListener('click', () => {
            // Видаляємо клас 'active' з усіх елементів з класом 'about-title'
            aboutTitles.forEach(title => title.classList.remove('active'));

            // Додаємо клас 'active' до клікнутого елемента
            title.classList.add('active');
        });
    });
}


// const share = document.querySelector('.share');
//
// setTimeout(() => {
//     share.classList.add("hover");
// }, 1000);
//
// setTimeout(() => {
//     share.classList.remove("hover");
// }, 3000);


// Перевіряємо, чи є на сторінці елемент з ідентифікатором 'characteristics'
if (window.innerWidth >= 992 && document.getElementById('characteristics')) {
    // Якщо елемент існує і ширина екрану дорівнює або більше 992px, то вимикаємо прокрутку, використовуючи вашу функцію
    disableBodyScroll();
}


export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}

$("document").ready(function () {
    bodyEl.css("visibility", "visible");

    new WOW().init();
});
