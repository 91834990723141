let lastScrollTop = 0;
const header = document.querySelector(".header");
const hideClass = "header--hidden";
const scrollThreshold = 10;
const showHeaderThreshold = 300;

window.addEventListener("scroll", () => {
  const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

  if (currentScrollTop < showHeaderThreshold) {
    if (header.classList.contains(hideClass)) {
      header.classList.remove(hideClass);
    }
  } else {
		if(currentScrollTop > lastScrollTop) {
			if (Math.abs(currentScrollTop - lastScrollTop) > scrollThreshold) {
				if (currentScrollTop > lastScrollTop && !header.classList.contains(hideClass)) {
					header.classList.add(hideClass);
				} else if (currentScrollTop < lastScrollTop && header.classList.contains(hideClass)) {
					header.classList.remove(hideClass);
				}	
			}
		}else{
			if (Math.abs(currentScrollTop - lastScrollTop) > (scrollThreshold * 15) ) {
				if (currentScrollTop > lastScrollTop && !header.classList.contains(hideClass)) {
					header.classList.add(hideClass);
				} else if (currentScrollTop < lastScrollTop && header.classList.contains(hideClass)) {
					header.classList.remove(hideClass);
				}	
			}
		}
  }

  lastScrollTop = currentScrollTop;
});
