document.addEventListener("scroll", (e) => {
  let scroll = window.scrollY;
  if (scroll > 3000) {
		document.querySelector(".scrollToTop")?.classList.remove("is-hidden");
   	document.querySelector(".scrollToTop")?.classList.remove("hide");
    document.querySelector(".scrollToTop").addEventListener("click", (e) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  } else if (scroll < 3000) {
    document.querySelector(".scrollToTop")?.classList.add("hide");
  }
});
