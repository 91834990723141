const links = document.querySelectorAll('.about-title');
if (links.length > 0) {
    // Додаємо клас 'active' першому посиланню за замовчуванням
    links[0].classList.add('active');

    links.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // щоб уникнути переходу по посиланню

            // Видаляємо клас 'active' у всіх посилань
            links.forEach(link => link.classList.remove('active'));

            // Додаємо клас 'active' до поточного посилання
            event.target.classList.add('active');

            // Отримуємо значення data-category поточного посилання
            const linkCategory = event.target.getAttribute('data-category');
            const headers = document.querySelectorAll('.ecosystem-list__item');
            if (headers.length > 0) {
                headers.forEach(header => {
                    if (header.getAttribute('data-category') === linkCategory) {
                        header.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            } else {
                console.warn('No headers found in .diagnostic-wrapper__content');
            }
        });
    });
} else {
    console.warn('No links found with class .pages_headings-list__link');
}
