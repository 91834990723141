// Вибираємо елемент заголовка
let title = document.querySelector('#hero-title .title');
if(title){

	let options = {
		root: null, 
		rootMargin: '0px 0px -200px 0px',
		threshold: 0
	};
	
	let callback = function(entries, observer) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				title.classList.add('animate-in');
				observer.unobserve(entry.target);
			}
		});
	};
	let observer = new IntersectionObserver(callback, options);
	observer.observe(title);
}
	
